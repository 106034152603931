/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(assets/themes/theme_athena.css);
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

/* custom classes*/
@layer components {
  .large-screen {
    min-height: calc(100vh - 142px) !important;
  }

  .small-screen {
    min-height: calc(100vh - 272px) !important;
  }
}

html,
body {
  height: 100%;
  min-height: 1px !important;
  background-color: #222222;
}

* {
  margin: 0;
  font-family: "Poppins", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
}

:root {
  scroll-behavior: smooth !important;
}

p {
  color: white;
  margin: 0;
  font-weight: 300;
}

label {
  color: white;
  font-weight: 300;
}

/* Scroll style */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #dcdcdc;
}

*::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 10px;
  background-color: #7a7a7a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.p-error {
  padding-top: 3px;
  font-size: 14px;
  font-weight: 600;
  color: #f75050;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.p-dropdown .p-dropdown-label {
  padding: 13px !important;
}

.p-dropdown {
  border-radius: 10px;
  background-color: transparent !important;
}

.p-input-icon-left>.p-inputtext {
  padding: 12px 35px !important;
  border-radius: 10px;
}

.p-inputtext {
  padding: 12px !important;
  border-radius: 10px;
  background-color: transparent !important;
}

.p-checkbox-box {
  background-color: transparent !important;
}

.p-button .p-button-label {
  padding: 4px !important;
}

.p-button {
  border-radius: 10px;
}

/* PRIMENG COMPONENTS STYLE */

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: transparent;
}

.p-dropdown .p-dropdown-item {
  white-space: normal;
  word-wrap: break-word;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  word-break: break-word;
}

label {
  padding-bottom: 5px;
}

.custom-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

a {
  color: #FFC04A;
}